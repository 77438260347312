<template>
  <div class="playlist-slide">
    <ImageSlide
      v-if="getSimpleType(slide.item_type) === 'image'"
      class="playlist-slide-image"
      :slide="slide"
    />

    <div v-else-if="getSimpleType(slide.item_type) === 'rss'" class="playlist-slide-app-rss">
      <rss-viewer :setting="getRSSAppSettings(slide)"></rss-viewer>
    </div>

    <iframe
      v-else-if="getSimpleType(slide.item_type) === 'layer'"
      id="layerIframe"
      :src="slide.linkUrl"
      frameborder="0"
      :style="iframeStyle"
      class="scrollbar"
    ></iframe>

    <!-- Table app -->
    <div v-else-if="getSimpleType(slide.item_type) === 'table'" class="playlist-slide-app-table">
      <TableViewer v-if="show" :settings="slide" :is_visible="show" />
    </div>

    <div
      v-else-if="getSimpleType(slide.item_type) === APP_TYPES.TRAFIKLAB"
      class="playlist-slide-trafiklab-table"
    >
      <TimeTableViewer
        :style="{ zoom: trafiklabZoom }"
        :viewerStyles="slide.viewerStyles"
        :timetable="slide.timetable"
        :appId="slide.item_id"
        :name="slide.timetable[0].stop.name"
        alt="Trafiklab app"
        @error="showPlaceholder = true"
      />
    </div>

    <div
      v-else-if="getSimpleType(slide.item_type) === APP_TYPES.WEATHER"
      class="playlist-slide-weather-table"
    >
      <WeatherViewOld v-if="isOldWeatherApp" class="weather-app" :app="slide" />
      <WeatherView v-else class="weather-app" :app="slide" />
    </div>

    <div v-else-if="getSimpleType(slide.item_type) === 'pdf'" class="playlist-slide-pdf">
      <PdfView :pdfImages="slide.pdf_content.thumbnail_url" />
    </div>

    <div v-else-if="getSimpleType(slide.item_type) === 'youtube'" class="player-slide-youtube">
      <YoutubeSlide :slide="slide" :isMuted="isMuted" />
    </div>

    <TemplateViewer
      v-else-if="show && getSimpleType(slide.item_type) === 'template'"
      :template="slide.template"
      :playlistId="playlist ? playlist.playlist_id : undefined"
    />

    <TileViewer
      v-else-if="show && getSimpleType(slide.item_type) === 'local-template'"
      :tile="slide.template"
      :playlistId="playlist?.playlist_id"
    />

    <div v-else class="playlist-background">
      Unable to view slide of type {{ getSimpleType(slide.item_type) }}
    </div>
  </div>
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import { APP_TYPES } from '@/models/layoutDesigner';
  import { CONTENT_TYPE_WEATHER_APP } from '@/constants';

  import RssViewer from '@/components/rssViewer/Index.vue';
  import TableAppWebView from '@/components/tableApp/TableAppWebView.vue';
  import ImageSlide from '@/components/player/layerContent/ImageSlide.vue';
  import YoutubeSlide from '@/components/player/layerContent/YoutubeSlide.vue';
  import WeatherView from '@/components/apps/weather/WeatherView.vue';
  import WeatherViewOld from '@/components/apps/weather/WeatherViewOld.vue';
  import TimeTableViewer from '@/components/common/timetable/TimeTableViewer.vue';
  import PdfView from '@/components/content/PdfView.vue';
  import TemplateViewer from '@/components/widgets/templates/TemplateViewer.vue';
  import TileViewer from '@/components/widgets/templates/TileViewer.vue';

  export default {
    name: 'PlaylistSlide',

    mixins: [simpleTypeMixin],

    components: {
      RssViewer,
      TableViewer: TableAppWebView,
      ImageSlide,
      YoutubeSlide,
      WeatherView,
      WeatherViewOld,
      TimeTableViewer,
      PdfView,
      TemplateViewer,
      TileViewer,
    },

    props: {
      slide: {
        type: Object,
        default: () => null,
      },
      show: {
        type: Boolean,
        default: false,
      },
      trafiklabZoom: {
        type: Number,
        default: 1,
      },
      playlist: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        APP_TYPES,
      };
    },

    computed: {
      iframeStyle() {
        return {
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        };
      },

      isOldWeatherApp() {
        return (
          this.isWeatherApp &&
          !this.slide.weather &&
          (this.slide.visibility || this.slide.background)
        );
      },

      isWeatherApp() {
        return this.slide.item_type === CONTENT_TYPE_WEATHER_APP;
      },
    },

    methods: {
      isMuted(itemData) {
        if (this.isLoading && !itemData) {
          return true;
        }
        return itemData.mute;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .playlist-background {
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0) url('../../assets/img/layoutDesigner/playlist.png') no-repeat
      center;
    background-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15%;
  }
</style>
